.flag__icon {
  margin-left: 0px;
  margin-right: 0px;
  height: $nav-toggle-height;
  border: 0;
  outline: none;
  color: $primary-color;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}