html {
    font-size: 18px;

    @include breakpoint($medium) {
        font-size: 18px; // change to whatever
    }

    @include breakpoint($large) {
        font-size: 20px; // change to whatever
    }

    @include breakpoint($x-large) {
        font-size: 20px; // change to whatever
    }
}